<template>
  <div>
    <div class="row">
      <div class="col-md-10 col-12 pt-3">
        <v-progress-linear
          stream
          color="bg-primary"
          class="mb-3"
          :value="currentPercent"
          :buffer-value="0"
        />
      </div>
      <div class="col-md-2 col-12 text-md-right text-center">
        <span class="font-size-lg">{{ currentProgress }} / {{ target }}</span>
        <i v-if="finishedLoading" class="ml-3 fas fa-check text-success" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoadingProgressBar",
  props: {
    currentProgress: {
      type: Number,
      default: 0
    },
    target: {
      type: Number,
      default: 0
    },
    finished: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    currentPercent() {
      return this.finished ? 100 : (this.currentProgress / this.target) * 100;
    },
    finishedLoading() {
      return this.currentPercent === 100;
    }
  }
};
</script>

<style scoped></style>
