<template>
  <div>
    <b-card title="Applikation-Setup" class="text-center">
      Diese Anwendung durchläuft einen kurzen Installationsprozess. Sie werden
      automatisch weitergeleitet.
      <p class="font-size-lg mt-5">
        {{ apiStateMessage }}
        <i
          v-if="apiState !== null"
          class="ml-3"
          :class="apiConnectionIconClass"
        />
      </p>
      <b-button
        v-if="apiState !== null && !isConnectedToApi"
        @click="startApplicationSetup"
      >
        Erneut versuchen
      </b-button>
      <div class="w-100">
        <span class="font-size-lg">
          {{ loadingStateMessage }}
          <i
            v-if="loadingState === states.LOADING_FINISHED"
            class="ml-3 fas fa-check text-success"
          />
        </span>
      </div>
      <div v-if="isConnectedToApi" class="mt-15 mb-10">
        <loading-progress-bar
          :current-progress="loadedAddressesCount"
          :target="maxAddressesCount"
          :finished="loadingState > states.LOADING_ADDRESSES"
        />
      </div>
      <div
        v-if="isConnectedToApi && loadingState > states.LOADING_ADDRESSES"
        class="mt-15 mb-10"
      >
        <loading-progress-bar
          :current-progress="loadedAddressProductsCount"
          :target="maxAddressProductsCount"
          :finished="loadingState > states.LOADING_PRODUCT_STATUS"
        />
      </div>
      <div
        v-show="isConnectedToApi && loadingState === states.LOADING_FINISHED"
        class="mt-15 mb-10"
      >
        <timer-countdown
          :seconds="5"
          message="Sie werden automatisch weitergeleitet..."
          ref="timer"
          @finished="pushToNextPage"
        />
      </div>
    </b-card>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "../../core/services/api.service";
import TimerCountdown from "../components/TimerCountdown";
import LoadingProgressBar from "../components/LoadingProgressBar";

const LOADING_ADDRESSES = 0;
const LOADING_PRODUCT_STATUS = 1;
const LOADING_HOUSING_INDUSTRIES = 2;
const LOADING_FINISHED = 3;

export default {
  name: "ApplicationSetup",
  components: { LoadingProgressBar, TimerCountdown },
  data() {
    return {
      apiState: null,
      loadingState: "",
      errorState: null,
      states: {
        LOADING_ADDRESSES,
        LOADING_PRODUCT_STATUS,
        LOADING_HOUSING_INDUSTRIES,
        LOADING_FINISHED
      }
    };
  },
  computed: {
    apiStateMessage() {
      if (this.apiState === null) {
        return "Verbindung zu DICLINA wird aufgebaut...";
      }

      switch (this.apiState.status) {
        case 200:
          return "Verbindung zu DICLINA ist aktiv.";
        case 401:
          return "Sie sind nicht angemeldet.";
        default:
          return "Verbindung zu DICLINA ist fehlerhaft.";
      }
    },
    isConnectedToApi() {
      if (this.apiState === null) {
        return false;
      }

      switch (this.apiState.status) {
        case 200:
          return true;
        default:
          return false;
      }
    },
    apiConnectionIconClass() {
      return {
        "fas fa-check text-success": this.isConnectedToApi,
        "fas fa-times text-danger": !this.isConnectedToApi
      };
    },
    loadedAddressesCount() {
      return this.$store.getters.loadedAddressesCount;
    },
    maxAddressesCount() {
      return this.$store.getters.maxAddressesCount;
    },
    loadedAddressProductsCount() {
      return this.$store.getters.loadedAddressProductsCount;
    },
    maxAddressProductsCount() {
      return this.$store.getters.maxAddressProductsCount;
    },
    loadingAddressPercent() {
      if (
        this.loadingState > LOADING_ADDRESSES &&
        this.loadedAddressesCount === this.maxAddressesCount
      ) {
        return 100;
      } else {
        return (this.loadedAddressesCount / this.maxAddressesCount) * 100;
      }
    },
    loadingAddressProductsPercent() {
      return (
        (this.loadedAddressProductsCount / this.maxAddressProductsCount) * 100
      );
    },
    loadingStateMessage() {
      switch (this.loadingState) {
        case LOADING_ADDRESSES:
          return "Adressen werden aktualisiert ...";
        case LOADING_PRODUCT_STATUS:
          return "Produktstatus wird aktualisiert ...";
        case LOADING_HOUSING_INDUSTRIES:
          return "Häuserwirtschaften werden aktualisiert ...";
        case LOADING_FINISHED:
          return "Alles aktualisiert.";
        default:
          return "";
      }
    }
  },
  methods: {
    async startApplicationSetup() {
      this.apiState = await ApiService.get("application-state");

      if (this.isConnectedToApi) {
        await this.loadAddresses();
        await this.loadAddressProductStatus();
        await this.loadHousingIndustries();

        if (this.maxAddressesCount === 0) {
          this.loadingState = LOADING_FINISHED;
        }
      }
    },
    async loadAddresses() {
      this.loadingState = LOADING_ADDRESSES;
      await this.$store.dispatch("updateAddresses");
    },
    async loadAddressProductStatus() {
      this.loadingState = LOADING_PRODUCT_STATUS;
      await this.$store.dispatch("updateAddressProductStatus");
    },
    async loadHousingIndustries() {
      this.loadingState = LOADING_HOUSING_INDUSTRIES;
      await this.$store.dispatch("updateHousingIndustries");
    },
    pushToNextPage() {
      this.$router.push({ name: "addresses" });
    }
  },
  watch: {
    loadedAddressesCount(value) {
      if (value > 0 && value === this.maxAddressesCount) {
        this.loadingState = LOADING_FINISHED;
      }
    },
    loadingState(value) {
      if (value === LOADING_FINISHED) {
        this.$refs["timer"].startCountdown();
      }
    }
  },
  async mounted() {
    await this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Applikation-Setup", subTitle: "Vertriebssteuerungstool" }
    ]);
    await this.$store.dispatch("resetLoadedAddressProductCount");

    await this.startApplicationSetup();
  }
};
</script>

<style lang="scss"></style>
